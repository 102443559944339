<template>
  <v-row class="overflow-x-auto flex-nowrap">
    <v-col
      v-for="(variable, index) in variables"
      :key="index"
    >
      <v-btn
        outlined
        color="primary"
        @click="$emit('sendCode', variable.code)"
      >
        {{ variable.name }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      variables: [
        {
          name: 'Genre',
          code: 'gender',
        },
        {
          name: 'Nom',
          code: 'nom',
        },
        {
          name: 'Prénom',
          code: 'prenom',
        },
        {
          name: 'E-mail',
          code: 'email',
        },
        {
          name: 'Mot de passe',
          code: 'password',
        },
        {
          name: 'Date du jour',
          code: 'date',
        },
        {
          name: 'Prochain rappel',
          code: 'PROCHAINRAPPEL',
        },
        {
          name: 'Nom formation',
          code: 'formation',
        },
        {
          name: 'Date début formation',
          code: 'debutFormation',
        },
        {
          name: 'Date fin formation',
          code: 'finFormation',
        },
      ],
    }
  },
}
</script>

<style>

</style>
