<template>
  <v-card>
    <v-card-title>
      Liste des textes
    </v-card-title>
    <v-divider></v-divider>
    <div>
      <v-tabs v-model="tabs">
        <v-tabs-slider></v-tabs-slider>
        <v-tab>Textes types</v-tab>
        <v-tab>Textes de contact</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <TexteTypeEditor
            v-if="!loading && textes.length > 0"
            :category="'type'"
            :list-texts="listTexts.filter(x => x.category == 'type' || x.category == 'all')"
            :variables-enabled="true"
            :subject-enabled="true"
            @updateText="updateText"
            @createText="createText"
            @deleteText="deleteText"
          >
          </TexteTypeEditor>
          <v-skeleton-loader
            v-else
            v-bind="attrs"
            type="article, actions"
          ></v-skeleton-loader>
        </v-tab-item>
        <v-tab-item>
          <TexteTypeEditor
            v-if="!loading && textes.length > 0"
            :category="'contact'"
            :list-texts="listTexts.filter(x => x.category == 'contact' || x.category == 'all')"
            :variables-enabled="false"
            :subject-enabled="false"
            @updateText="updateText"
            @createText="createText"
            @deleteText="deleteText"
          ></TexteTypeEditor>
          <v-skeleton-loader
            v-else
            v-bind="attrs"
            type="article, actions"
          ></v-skeleton-loader>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-card>
</template>

<script>
import TexteTypeEditor from './TexteTypeEditor.vue'

export default {
  components: {
    TexteTypeEditor,
  },
  data() {
    return {
      loading: true,
      tabs: null,
      textes: [],
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  computed: {
    listTexts() {
      const { textes } = this

      textes.sort((a, b) => b.id - a.id)

      return textes
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/template`)
        .then(textes => {
          textes.data.push(
            {
              title: 'Nouveau Text',
              content: '',
              _id: 0,
              category: 'all',
            },
          )
          this.textes = textes.data
          this.loading = false
        })
        .catch(error => console.log(error))
    },
    updateText(payload) {
      this.$http.patch(`${process.env.VUE_APP_API_URL}/template/${payload.id}`, {
        content: payload.content,
        title: payload.title,
        emailSubject: payload.emailSubject,
      })
        .then(updatedText => {
          const foundIndex = this.textes.findIndex(x => x._id === updatedText.data._id)
          this.textes.splice(foundIndex, 1, updatedText.data)

          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Texte mis à jour',
            value: true,
          })
        })
        .catch(error => {
          console.error(error)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la mise à jour',
            value: true,
          })
        })
    },
    createText(payload) {
      this.$http.post(`${process.env.VUE_APP_API_URL}/template/new`, {
        title: payload.title, content: payload.content, category: payload.category,
      })
        .then(newText => {
          console.log(newText)
          this.textes.push(newText.data)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Nouveau texte ajouté',
            value: true,
          })
        })
        .catch(error => {
          console.error(error)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la création',
            value: true,
          })
        })
    },
    deleteText(payload) {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/template/${payload.id}`)
        .then(deletedText => {
          const foundIndex = this.textes.findIndex(x => x._id === deletedText._id)
          this.textes.splice(foundIndex, 1)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Texte supprimé',
            value: true,
          })
        })
        .catch(error => {
          console.error(error)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression',
            value: true,
          })
        })
    },

  },
}
</script>

<style>

</style>
